<script>
export default {
  name: 'RestartButton',
  props: {
    backgroundColor: {
      type: String,
      default: '#FA4801',
    },
    fontColor: {
      type: String,
      default: '#FFFFFF',
    },
    text: {
      type: String,
      default: 'Restart',
    },
  },
  data: () => ({}),
  methods: {},
  mounted() {},
};
</script>
<template>
  <button @click="$emit('restart-trivia')" class="button_restart">
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.7551 8.63847C15.7551 12.6547 12.4838 15.8936 8.5 15.8936C6.91294 15.8936
    5.39066 15.4078 4.12749 14.4685C3.67405 14.1122 3.57688 13.4644 3.93316 13.011C4.28944
    12.5575 4.93722 12.4604 5.39066 12.7843C6.29755 13.4644 7.36639 13.8207 8.5 13.8207C11.3502
    13.8207 13.6822 11.5211 13.6822 8.63847C13.6822 5.78824 11.3502 3.48863 8.5 3.48863C6.84816
    3.48863 5.32588 4.26596 4.35422 5.52913H6.42711C6.97772 5.52913 7.46355 6.01496 7.46355
    6.56557C7.46355 7.14858 6.97772 7.60202 6.42711 7.60202H1.7631C1.1801 7.60202 0.726654
    7.14858 0.726654 6.56557V1.90157C0.726654 1.35096 1.1801 0.865121 1.7631 0.865121C2.31371
    0.865121 2.79955 1.35096 2.79955 1.90157V4.20118C4.15988 2.45218 6.23277 1.41573 8.5
    1.41573C12.4838 1.41573 15.7551 4.65463 15.7551 8.63847Z" :fill="fontColor"/>
    </svg>

    <span>{{ text }}</span>
  </button>
</template>

<style lang="scss" scoped>
.button_restart {
  background: none;
  color: inherit;
  border: none;
  padding: 0px 15px;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  position: absolute;
  min-width: 159px;
  height: 42px;
  right: 55px;
  top: 25px;
  transition: all 125ms;

  background: v-bind(backgroundColor);
  border-radius: 58px;
  color: v-bind(fontColor);
  font-size: 16px;
  font-family: 'Futura Std Bold';
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  &:hover{
    opacity: 0.8;
  }
}
</style>
