<!-- eslint-disable max-len -->
<template>
  <div class="age-view-content bg-content">
    <RestartButton
      @click="restartTrivia"
      backgroundColor="#FFFFFF"
      fontColor="#FA4801"
      :text="restartButtonText"
    />
    <div class="form">
      <div class="head">
        <img src="../../public/img/images/logo_site.svg" alt="">
        <h2>
          You win! We are bubbling with joy!
        </h2>
        <h3>
          <b>Scan the code </b> to get your special offer.
        </h3>
      </div>
      <div class="body">
        <QRCode />

        <p class="bold">
          If you have any issue scanning or loading the page,
          take a photo and show it to one of our Aperol Ambassadors.
        </p>
        <p class="light">
          Aperol® Liqueur. 11% alc./vol. (22 Proof).
          ©2024 Campari America, New York, NY. Please enjoy responsibly.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import RestartButton from '@/components/RestartButton.vue';
import QRCode from '@/components/QRCode.vue';

export default {
  name: 'WinnerView',
  components: {
    RestartButton,
    QRCode,
  },
  data() {
    return {
      timer: null,
      timeout: 30,
      remaining: 30,
    };
  },
  methods: {
    restartTrivia() {
      this.$router.push('/');
    },
    formatTime(numSeconds) {
      const minutes = Math.floor(numSeconds / 60);
      const seconds = numSeconds % 60;
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
      // if (minutes > 0) {
      //   return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
      // }
      // return `${seconds}`;
    },
    startTimer() {
      if (this.timer == null) {
        this.resetTimer();
      }
      this.timer = setInterval(() => {
        this.remaining -= 1;
        if (this.remaining <= 0) {
          this.remaining = this.timeout;
          this.resetTimer();
          this.$router.push('/');
        }
      }, 1000);
    },
    resetTimer() {
      if (this.timer == null) {
        return;
      }
      clearInterval(this.timer);
      this.timer = null;
    },
  },
  computed: {
    restartButtonText() {
      const { remaining, formatTime } = this;
      const remainingString = formatTime(remaining);
      return `Restart ${remainingString}`;
    },
  },
  mounted() {
    this.startTimer();
  },
  beforeUnmount() {
    this.resetTimer();
  },
};
</script>

<style lang="scss">
.age-view-content {
  .form {
    .body {
      gap: 0;
    }
  }

  p.light {
    font-family: 'Futura Std Light' !important;
  }
  .el-message-box__title {
    padding-left: 10px;

    span {
      font-family: "Futura Std Book", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 48px;
      text-transform: uppercase;
    }
  }

  .el-message-box__message {
    text-align: center;

    p {
      font-family: "Futura Std Light", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      text-transform: uppercase;
    }
  }

  .el-message-box__btns {
    .el-button {
      font-family: "Futura Std Bold", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 37px;
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: uppercase;
      color: #FA4700;
      background: #FFFFFF;
      box-shadow: 0px 6.20541px 12.4108px rgba(183, 53, 2, 0.88);
      border-radius: 54px;
      border: 1px solid;
    }
  }

  h3{
    font-family: 'Futura Std Book';
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 140%;
    text-align: center;
    color: #FFFFFF;
    b{
      font-weight: bold;
    }
  }

  h2{
    margin-bottom: 0;
    padding: 0;
  }

  h3{
    margin: 0;
    padding: 0;
  }

  .body{
    p{
      max-width: 499px;
      font-family: 'Futura Std Book';
      font-style: normal;
      font-weight: 650;
      font-size: 18px;
      line-height: 140%;
      text-align: center;
      color: #FFFFFF;
    }
  }

  .button_restart {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;

    position: absolute;
    width: 129px;
    height: 42px;
    right: 55px;
    top: 25px;

    background: #FFFFFF;
    border-radius: 58px;
    color: #FA4801;
    font-size: 16px;
    font-family: 'Futura Std Bold';
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;

    :hover{
      opacity: 0.8;
    }
  }
}

</style>
