<!-- eslint-disable max-len -->
<template>
  <div class="intro-view__aperol-spritz">
    <svg width="819" height="327" viewBox="0 0 819 327" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M818.548 158.026V10.0334L811.023 0L0.824097 316.053L8.34917 326.086L818.548 158.026Z" fill="white"/>
      <path d="M811.023 0L0.824097 173.077V316.053L811.023 147.993V0Z" fill="#F26D1D"/>
      <path d="M574.988 179.943C568.94 181.152 563.001 182.362 556.843 183.682C554.864 146.951 552.884 110.22 550.905 73.3797C556.183 72.28 561.242 71.0703 566.411 70.1905C570.809 69.4207 575.318 68.431 579.827 68.321C592.254 67.9911 599.402 73.7096 602.151 85.8065C604.351 95.2641 604.131 104.612 600.392 113.629C598.742 117.698 596.323 121.327 593.244 124.407C592.144 125.396 592.034 126.166 592.584 127.596C598.302 141.892 604.021 156.189 609.74 170.375C610.069 171.145 610.289 171.805 610.619 172.794C604.131 174.114 597.863 175.324 591.484 176.643C588.295 168.615 585.216 160.807 582.137 152.889C579.057 145.081 575.978 137.163 572.459 129.465C573.119 146.181 574.109 163.007 574.988 179.943ZM570.04 87.5661C570.59 97.4636 571.139 107.141 571.579 116.929C576.198 116.599 580.157 115.389 582.576 111.43C586.535 104.942 586.535 98.0134 583.786 91.0852C582.357 87.6761 579.277 86.1364 575.538 86.5763C573.779 86.6863 572.019 87.1262 570.04 87.5661Z" fill="white"/>
      <path d="M357.904 174.994C358.014 185.881 357.024 196.658 353.285 207.106C350.975 213.704 347.566 219.642 342.398 224.481C329.751 236.138 312.925 233.829 304.017 219.202C299.619 211.834 297.309 203.806 296.099 195.449C294.01 181.482 293.68 167.626 296.319 153.769C298.079 144.751 301.048 136.284 306.987 129.245C311.605 123.747 317.214 120.008 324.362 118.908C333.27 117.588 340.418 120.998 345.917 127.816C351.085 134.304 353.615 141.892 355.374 149.81C357.134 158.058 357.904 166.526 357.904 174.994ZM313.255 174.994C313.695 178.953 314.135 185.221 315.015 191.6C315.784 197.098 316.994 202.377 319.963 207.216C323.592 212.934 329.641 213.044 333.49 207.545C335.799 204.356 336.899 200.617 337.449 196.768C339.868 181.922 339.098 167.076 335.909 152.34C335.029 148.6 333.71 144.971 331.4 141.782C328.321 137.933 323.482 137.493 320.073 141.012C318.534 142.662 317.214 144.861 316.334 147.061C313.255 155.309 313.365 163.997 313.255 174.994Z" fill="white"/>
      <path d="M289.831 237.568C284.442 238.668 279.274 239.767 273.995 240.757C271.246 241.307 271.246 241.307 270.256 238.778C264.757 224.481 259.259 210.185 253.76 195.888C253.65 195.559 253.43 195.229 252.99 195.009C252.99 195.449 252.99 195.888 252.99 196.328C253.76 211.724 254.64 227.23 255.52 242.627C255.63 244.056 255.3 244.606 253.76 244.936C249.141 245.706 244.523 246.805 239.904 247.685C239.354 247.795 238.694 247.905 238.034 247.905C237.924 247.355 237.924 246.805 237.814 246.366C235.945 211.834 234.185 177.193 232.316 142.662C232.096 138.923 231.766 139.693 235.175 139.033C241.773 137.603 248.372 136.284 254.97 135.074C260.139 134.194 265.307 134.194 270.256 136.394C276.634 139.143 279.824 144.532 281.363 150.91C283.893 161.687 283.673 172.244 278.394 182.252C276.964 185.001 274.985 187.421 272.895 189.73C271.796 190.94 271.796 191.819 272.345 193.249C277.954 207.545 283.563 221.842 289.171 236.138C289.501 236.578 289.611 236.908 289.831 237.568ZM252.441 182.472C257.279 182.142 261.348 180.492 263.658 176.093C266.297 171.035 266.407 165.756 265.197 160.367C263.768 153.879 260.139 151.68 253.65 153.109C253.21 153.219 252.66 153.329 252.221 153.439C251.011 153.549 250.791 154.099 250.791 155.309C251.341 163.887 251.671 172.354 252.221 180.932C252.221 181.262 252.331 181.812 252.441 182.472Z" fill="white"/>
      <path d="M698.927 63.4822C698.597 56.554 698.157 49.7357 697.827 42.8075C716.082 38.9585 734.118 35.2194 752.813 31.3704C743.685 63.9221 734.778 96.1439 725.65 128.586C735.657 126.606 745.225 124.627 755.122 122.647C755.562 129.575 755.892 136.394 756.222 143.322C737.417 147.061 718.832 150.91 699.807 154.759C708.604 122.427 717.182 90.3154 725.98 57.8737C716.962 59.7432 708.054 61.5027 698.927 63.4822Z" fill="white"/>
      <path d="M68.3474 282.326C69.1172 276.278 69.887 270.669 70.6568 264.951C73.5161 242.846 76.4853 220.632 79.3446 198.528C80.5543 189.62 81.654 180.712 82.8637 171.915C82.9737 171.365 83.4136 170.595 83.8535 170.485C89.462 169.275 95.1806 168.066 101.119 166.856C110.027 201.277 118.935 235.698 127.842 270.23C121.574 271.439 115.635 272.649 109.367 273.969C107.937 268.14 106.508 262.422 105.078 256.483C102.989 256.923 101.119 257.253 99.2495 257.693C95.8404 258.353 92.4313 259.122 89.0221 259.782C87.9224 260.002 87.4825 260.442 87.3726 261.542C86.8227 266.82 86.1629 272.099 85.503 277.378C85.3931 277.928 84.9532 278.807 84.5133 278.917C79.3446 280.127 73.956 281.117 68.3474 282.326ZM92.8712 196.878C91.7714 212.384 90.6717 227.45 89.682 242.627C93.7509 241.747 97.7099 240.977 101.669 240.207C98.6997 225.801 95.8404 211.504 92.8712 196.878Z" fill="white"/>
      <path d="M513.184 146.621C513.954 161.797 514.834 176.753 515.603 192.039C509.555 193.249 503.616 194.459 497.568 195.669C497.348 191.27 497.018 187.201 496.798 183.022C495.918 165.756 495.039 148.491 494.049 131.225C493.279 116.599 492.509 102.082 491.63 87.5661C491.52 86.2464 491.849 85.6966 493.279 85.3667C501.417 83.827 509.445 81.8475 517.583 80.6379C528.58 78.9883 537.048 83.827 540.677 94.2744C544.856 106.371 544.966 118.688 538.808 130.345C533.969 139.693 525.941 144.752 515.603 146.291C514.834 146.401 514.174 146.401 513.184 146.621ZM512.304 128.806C519.123 128.146 523.961 123.967 524.731 117.588C525.171 114.399 525.061 110.99 524.621 107.801C523.411 100.323 519.782 98.0134 512.414 99.553C511.095 99.883 510.655 100.323 510.765 101.643C511.315 110.11 511.644 118.578 512.194 127.046C512.194 127.596 512.304 128.146 512.304 128.806Z" fill="white"/>
      <path d="M152.366 265.281C146.538 266.49 140.929 267.59 134.99 268.8C134.88 267.26 134.771 265.941 134.661 264.621C132.901 230.86 131.141 197.098 129.272 163.227C129.162 161.357 129.712 160.697 131.471 160.368C139.169 158.938 146.757 157.068 154.456 155.969C164.793 154.429 173.151 159.598 176.34 169.605C180.299 181.812 180.409 194.019 174.14 205.676C169.522 214.474 161.714 219.203 151.926 220.522C150.277 220.742 149.947 221.182 150.057 222.832C150.936 236.908 151.596 250.984 152.366 265.281ZM149.177 203.477C153.796 202.707 157.755 201.057 159.624 196.878C162.483 190.61 162.264 184.121 159.404 177.963C158.085 174.994 155.335 173.784 152.146 174.114C151.156 174.224 150.277 174.554 149.287 174.554C147.857 174.664 147.527 175.434 147.637 176.753C148.077 185.001 148.517 193.249 148.957 201.497C149.067 202.047 149.177 202.707 149.177 203.477Z" fill="white"/>
      <path d="M191.956 257.363C191.626 252.084 191.406 247.025 191.076 241.967C189.537 212.054 187.887 182.142 186.347 152.34C186.127 148.601 185.797 149.37 189.207 148.601C199.324 146.511 209.441 144.422 219.559 142.332C220.109 142.222 220.659 142.112 221.318 142.002C221.428 142.552 221.538 142.992 221.538 143.432C221.868 149.04 222.088 154.649 222.528 160.258C222.638 161.687 222.198 162.127 220.879 162.457C216.04 163.337 211.201 164.436 206.362 165.426C205.372 165.646 204.823 165.976 204.933 167.076C205.372 174.224 205.702 181.372 206.142 188.52C206.142 188.74 206.252 188.85 206.362 189.29C211.421 188.3 216.48 187.201 221.648 186.211C221.758 187.091 221.758 187.861 221.868 188.52C222.198 193.909 222.418 199.408 222.748 204.796C222.858 205.896 222.528 206.446 221.318 206.556C217.139 207.326 213.071 208.205 208.892 209.085C207.572 209.305 207.132 209.855 207.242 211.285C207.682 218.323 208.012 225.361 208.342 232.289C208.342 232.729 208.452 233.169 208.562 233.829C214.5 232.619 220.439 231.409 226.487 230.2C226.817 237.018 227.147 243.506 227.477 250.325C215.6 252.524 203.943 254.943 191.956 257.363Z" fill="white"/>
      <path d="M477.113 112.97C474.144 111.76 471.505 110.22 468.645 109.56C461.717 107.911 455.889 112.64 455.339 119.788C454.899 125.726 457.208 130.235 461.827 133.754C465.456 136.394 469.195 138.813 472.824 141.342C480.083 146.511 484.152 153.659 485.031 162.457C486.021 172.684 484.921 182.582 479.533 191.6C473.484 201.827 461.497 207.216 450.5 204.576C450.17 204.466 449.84 204.356 449.4 204.246C445.221 203.037 445.221 202.927 445.002 198.638C444.672 193.139 444.342 187.531 444.012 181.702C445.221 182.362 446.211 182.802 447.311 183.352C451.49 185.221 455.779 185.881 460.068 184.012C468.975 180.162 470.625 166.856 462.817 161.027C460.178 159.048 457.208 157.288 454.349 155.639C443.572 149.48 438.183 140.023 437.743 127.706C437.413 119.128 438.623 110.88 442.912 103.292C449.4 91.635 462.487 86.9062 474.364 91.745C475.574 92.2949 476.124 92.9547 476.124 94.2744C476.344 100.103 476.673 105.821 477.003 111.65C477.113 112.2 477.113 112.75 477.113 112.97Z" fill="white"/>
      <path d="M643.501 74.9193C643.391 72.39 643.171 70.0805 643.061 67.7711C642.841 63.7022 642.621 59.7432 642.401 55.6742C642.401 54.7944 642.511 54.2446 643.501 54.0246C658.567 50.9454 673.633 47.7562 688.699 44.677C688.919 44.677 689.139 44.677 689.579 44.677C689.689 45.1169 689.799 45.6668 689.799 46.1067C690.129 51.9352 690.349 57.6537 690.789 63.4822C690.899 64.9119 690.349 65.2418 689.139 65.4617C685.62 66.1216 682.101 67.0013 678.582 67.5512C676.602 67.8811 676.272 68.6509 676.272 70.5204C677.262 87.3461 678.142 104.172 679.022 120.998C679.682 132.875 680.231 144.642 681.001 156.519C681.111 158.058 680.671 158.608 679.132 158.938C673.743 159.928 668.355 161.027 662.636 162.237C660.986 132.105 659.337 102.192 657.797 71.9501C653.068 72.9398 648.34 73.9296 643.501 74.9193Z" fill="white"/>
      <path d="M406.621 213.924C394.304 216.453 382.317 218.873 370.111 221.292C369.891 218.213 369.781 215.463 369.561 212.604C368.901 199.628 368.131 186.651 367.471 173.674C366.811 161.247 366.152 148.93 365.492 136.504C365.052 129.025 364.722 121.547 364.282 113.959C364.172 112.53 364.612 111.98 366.042 111.65C371.21 110.66 376.489 109.56 381.988 108.351C383.527 137.933 385.177 167.516 386.716 197.318C392.985 196.108 399.033 194.789 405.412 193.579C405.851 200.507 406.291 207.216 406.621 213.924Z" fill="white"/>
      <path d="M634.043 56.1141C634.263 58.8634 634.373 61.5027 634.483 64.252C636.243 97.5735 638.112 130.785 639.872 164.107C639.982 166.746 639.982 166.746 637.342 167.296C632.174 168.285 627.115 169.385 621.726 170.375C621.616 169.605 621.616 169.055 621.616 168.505C620.407 146.401 619.197 124.407 618.097 102.302C617.328 88.8857 616.668 75.4691 615.898 62.0526C615.788 60.183 616.448 59.5232 618.097 59.3033C622.826 58.4235 627.445 57.4337 632.064 56.444C632.614 56.334 633.273 56.334 634.043 56.1141Z" fill="white"/>
    </svg>

  </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
.intro-view__aperol-spritz {
}
</style>
