<!-- eslint-disable max-len -->
<template>
  <div class="intro-view__trivia-game">
    <svg width="659" height="314" viewBox="0 0 659 314" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M658.088 174.164V10.0509L647.184 0.236816L0.108154 303.163L7.63322 313.197L658.088 174.164Z" fill="white"/>
      <path d="M647.729 0L0 145.14V305.018L647.729 164.895V0Z" fill="#F26D1D"/>
      <path d="M548.417 168.051C543.783 168.988 539.399 169.922 534.639 170.861C531.391 142.271 528.271 113.93 525.158 86.0908C524.938 88.6051 524.851 91.6201 524.513 94.7638C523.313 109.095 522.112 123.426 520.909 137.631C519.925 149.196 518.815 160.763 517.957 172.453C517.851 173.961 517.355 174.47 515.977 174.738C513.471 175.147 511.091 175.679 508.211 176.343C501.835 148.671 495.584 120.997 489.076 92.8242C489.446 122.205 489.81 151.083 490.175 180.086C486.042 180.892 481.909 181.823 477.4 182.759C477.383 181.378 477.367 180.122 477.353 178.992C477.448 156.638 477.418 134.286 477.511 111.806C477.538 93.9741 477.564 76.1419 477.591 58.3096C477.564 56.1751 478.181 55.2883 480.31 54.8848C483.319 54.4701 486.323 53.6788 489.454 53.0115C496.348 81.933 503.241 110.729 510.008 139.526C510.134 139.525 510.259 139.523 510.385 139.522C510.599 136.631 510.689 133.741 510.905 130.976C512.421 111.743 514.064 92.635 515.581 73.5282C516.232 65.3575 517.135 57.3092 517.785 49.1385C517.889 47.3791 518.509 46.7434 520.138 46.4717C523.396 45.9284 526.525 45.1355 530.157 44.3363C536.327 85.5734 542.371 126.687 548.417 168.051Z" fill="white"/>
      <path d="M134.459 193.483C141.979 212.225 149.369 230.717 156.891 249.584C152.13 250.523 147.621 251.459 142.988 252.522C135.72 233.777 128.455 215.283 121.19 196.79C121.064 196.791 120.813 196.794 120.689 196.921C121.943 216.747 123.322 236.445 124.577 256.396C120.194 257.33 115.811 258.264 111.302 259.2C111.001 255.311 110.703 251.548 110.53 247.782C109.299 229.84 108.194 211.897 106.964 193.955C105.727 175.511 104.614 156.939 103.377 138.495C103.226 136.488 103.719 135.728 105.723 135.326C111.737 134.246 117.621 132.79 123.509 131.711C133.282 129.956 139.984 133.639 143.116 143.018C147.553 156.273 147.722 169.707 143.244 183.075C141.667 187.49 138.825 191.168 134.459 193.483ZM120.066 187.385C125.715 187.189 130.188 183.365 131.367 177.322C133.145 168.886 132.536 160.48 130.048 152.349C128.348 146.971 125.063 145.38 119.549 146.328C117.795 146.601 117.3 147.236 117.448 148.992C118.348 160.659 118.998 172.33 119.773 183.999C119.912 185.127 120.052 186.255 120.066 187.385Z" fill="white"/>
      <path d="M407.549 130.52C407.706 133.029 408.112 135.284 408.142 137.67C408.521 147.837 409.151 158.001 409.028 168.174C409.005 176.337 407.349 184.395 403.423 191.728C398.511 200.58 387.273 205.619 377.575 203.355C372.411 202.164 368.853 198.819 366.162 194.583C362.062 188.105 360.337 180.717 359.113 173.324C356.125 155.404 355.146 137.459 355.169 119.375C355.182 110.459 355.95 101.659 358.858 93.2087C363.703 79.083 374.94 73.9184 386.38 74.9045C392.163 75.4595 396.472 78.5447 399.296 83.4066C401.222 86.773 402.65 90.5223 404.204 94.27C404.462 94.7691 404.223 95.7767 403.85 96.0325C399.994 98.9694 396.137 101.781 392.155 104.719C391.629 102.842 391.106 101.216 390.584 99.5902C390.191 98.3394 389.546 96.9662 389.028 95.7169C385.161 87.8543 376.15 90.2282 373.198 95.1629C371.108 98.7053 370.025 102.486 369.95 106.506C369.686 115.425 369.423 124.47 370.039 133.504C370.685 144.923 371.834 156.336 373.357 167.619C374.052 172.884 375.626 178.264 377.824 183.26C380.543 189.755 388.834 190.028 392.4 183.955C393.999 181.298 394.712 178.149 395.177 175.13C396.571 166.196 395.704 157.166 394.962 148.133C391.08 148.936 387.446 149.609 383.565 150.537C383.397 147.149 383.106 144.013 382.941 140.876C382.63 136.108 382.755 136.106 387.264 135.17C393.277 133.964 399.288 132.633 405.3 131.427C405.795 130.793 406.546 130.658 407.549 130.52Z" fill="white"/>
      <path d="M442.867 63.1422C453.042 103.324 463.216 143.381 473.519 183.687C468.885 184.624 464.376 185.56 459.617 186.625C457.761 178.862 456.031 171.098 454.174 163.21C453.422 163.345 452.794 163.353 452.168 163.487C446.532 164.688 441.022 165.887 435.384 166.963C433.881 167.233 433.512 167.866 433.405 169.374C432.868 176.539 432.079 183.706 431.541 190.871C431.433 192.254 430.937 192.762 429.559 193.031C425.801 193.706 421.918 194.508 417.911 195.438C418.127 192.673 418.347 190.158 418.693 187.768C421.398 163.246 424.103 138.725 426.808 114.203C428.497 98.7359 430.186 83.2687 431.751 67.9286C431.981 66.2932 432.347 65.4095 434.102 65.1363C436.856 64.4737 439.61 63.8111 442.867 63.1422ZM439.056 89.5614C438.93 89.5629 438.805 89.5645 438.679 89.5661C437.316 110.931 436.078 132.295 434.718 153.911C440.479 152.709 445.74 151.638 450.875 150.568C446.849 130.025 442.951 109.73 439.056 89.5614Z" fill="white"/>
      <path d="M295.585 94.6335C305.633 134.692 315.677 174.373 325.726 214.431C321.091 215.368 316.708 216.303 312.073 217.24C311.145 213.359 310.344 209.602 309.42 205.972C308.622 202.466 307.699 198.961 307.025 195.328C306.76 194.201 306.253 193.831 305.126 194.096C299.365 195.299 293.479 196.629 287.716 197.706C286.338 197.974 286.224 198.855 286.112 199.986C285.448 207.027 284.91 214.192 284.245 221.232C284.136 222.49 283.768 223.248 282.39 223.516C278.632 224.191 274.874 224.992 270.868 225.922C271.198 222.276 271.656 218.754 271.99 215.359C274.46 192.096 277.057 168.957 279.527 145.694C281.214 130.102 282.901 114.509 284.588 98.9161C284.696 97.5334 285.189 96.7737 286.694 96.6292C289.574 95.965 292.328 95.3025 295.585 94.6335ZM287.428 184.775C293.064 183.574 298.324 182.503 303.334 181.435C299.435 161.015 295.539 140.846 291.644 120.678C291.518 120.679 291.392 120.681 291.267 120.682C290.027 141.92 288.788 163.159 287.428 184.775Z" fill="white"/>
      <path d="M573.282 148.148C581.173 146.541 588.687 144.94 596.578 143.333C596.89 148.101 597.196 152.493 597.377 156.886C597.382 157.262 596.387 158.028 595.761 158.162C587.245 160.027 578.728 161.767 570.21 163.507C566.954 164.176 563.697 164.844 560.065 165.644C557.276 123.611 554.489 81.8285 551.699 39.7953C564.223 37.1261 576.496 34.4599 589.146 31.7891C589.457 36.5571 589.763 40.9484 589.944 45.3413C589.949 45.718 588.954 46.484 588.328 46.6174C581.691 48.0824 575.179 49.5457 568.541 50.8851C567.163 51.1536 566.667 51.6622 566.81 53.0418C567.715 65.0857 568.496 77.2569 569.276 89.3024C569.28 89.6791 569.411 90.0542 569.546 90.806C576.434 89.3378 583.198 87.9969 590.336 86.4C590.493 88.9096 590.649 91.2936 590.803 93.5521C590.95 95.3083 590.973 97.0661 591.246 98.8207C591.39 100.326 591.023 101.084 589.394 101.356C583.631 102.433 577.871 103.761 572.235 104.962C571.109 105.228 570.359 105.488 570.501 106.868C571.422 120.167 572.343 133.467 573.139 146.768C573.018 147.146 573.148 147.521 573.282 148.148Z" fill="white"/>
      <path d="M231.463 108.376C231.025 113.53 230.582 118.182 230.138 122.834C228.918 135.658 227.698 148.482 226.604 161.305C224.95 179.534 223.296 197.764 221.516 215.995C220.968 222.281 220.417 228.441 219.743 234.728C219.63 235.734 219.514 236.489 218.386 236.629C215.253 237.171 212.248 237.836 208.867 238.632C199.701 198.814 190.41 159.124 181.115 119.056C185.499 118.122 189.882 117.188 194.515 116.125C200.419 146.189 206.195 176.003 211.971 205.943C212.097 205.942 212.223 205.94 212.348 205.939C212.552 202.169 212.63 198.4 212.834 194.631C214.634 168.111 216.435 141.592 218.236 115.072C218.549 110.045 217.936 111.183 222.569 110.12C225.45 109.582 228.206 109.045 231.463 108.376Z" fill="white"/>
      <path d="M75.2283 266.561C72.8863 230.174 70.5474 194.037 68.0815 157.776C62.8207 158.847 57.8111 159.915 52.4264 161.113C52.1167 156.47 51.8103 152.079 51.7533 147.559C51.7486 147.182 52.8723 146.666 53.4985 146.532C65.1452 144 76.792 141.467 88.4403 139.06C90.694 138.529 92.9493 138.124 95.4541 137.59C95.7638 142.233 96.0686 146.498 96.2496 150.891C96.2543 151.268 95.3833 151.907 94.8826 152.039C91.127 152.965 87.2442 153.768 83.4854 154.443C81.8563 154.714 81.362 155.349 81.5097 157.105C82.7293 174.168 83.8248 191.358 84.9188 208.423C86.0223 226.241 87.2513 244.057 88.4804 261.874C88.6249 263.379 88.2577 264.137 86.6287 264.409C82.8667 264.833 79.2366 265.757 75.2283 266.561Z" fill="white"/>
      <path d="M248.572 230.472C248.118 224.325 247.794 218.552 247.343 212.656C245.022 177.9 242.827 143.269 240.506 108.513C240.361 107.008 240.603 106.251 242.358 105.978C246.116 105.303 249.872 104.377 253.88 103.573C254.019 104.575 254.155 105.453 254.166 106.332C256.654 144.35 259.144 182.494 261.632 220.513C261.782 222.394 262.056 224.275 261.955 226.16C261.962 226.787 261.346 227.674 260.845 227.806C256.84 228.861 252.83 229.54 248.572 230.472Z" fill="white"/>
      <path d="M172.099 121.053C174.754 162.335 177.531 203.363 180.186 244.645C175.802 245.579 171.419 246.513 166.784 247.451C166.481 243.31 166.182 239.547 166.009 235.782C163.839 203.033 161.795 170.409 159.625 137.661C159.323 133.646 159.149 129.756 158.847 125.741C158.704 124.361 159.073 123.729 160.451 123.46C164.209 122.785 167.967 121.984 172.099 121.053Z" fill="white"/>
    </svg>

  </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
.intro-view__trivia-game {
}
</style>
